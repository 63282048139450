import React, { useState, useEffect } from 'react';
import logo from '../img/logo.svg';
import closeButton from '../img/close_button.svg';
import { useWindowSize } from './UseWindowSize';

const Nav = () => {
  const _visibility = {
    visibility: 'hidden',
  };

  const size = useWindowSize();

  const [open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState(_visibility);

  useEffect(() => {
    const isVisible = open || size.width > 768 ? 'visible' : 'hidden';
    // console.log(open, size.width);

    setVisibility({ visibility: isVisible });
  }, [open, size]);

  function toggleNav() {
    open ? setOpen(false) : setOpen(true);
  }

  return (
    <React.Fragment>
      <nav className="main-nav">
        <div className="brand">
          <img src={logo} alt="Side logo" className="site-logo" />
        </div>
        <ul className="nav-list" id="main-nav" onClick={toggleNav} style={visibility}>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about-page">About Us</a>
          </li>
          <li>
            <a href="#services-page">Services</a>
          </li>
          <li>
            <a href="#teams-page">Expertise</a>
          </li>
          <li>
            <a href="#values">Values</a>
          </li>
          <li>
            <a href="#contact-page">Contact Us</a>
          </li>
          <span className="close-btn" onClick={toggleNav}>
            <img src={closeButton} alt="close menu button" />
          </span>
        </ul>
        <span className="hamburger" onClick={toggleNav}></span>
      </nav>
    </React.Fragment>
  );
};

export default Nav;
